export const USER_DETAILS_FORM: UserDetailsFormConfigProps = {
  AGE_OPTIONS: [
    { text: '-- Bitte auswählen --', value: null },
    { value: '<18', text: 'Unter 18' },
    { value: '18-25', text: '18-25' },
    { value: '26-35', text: '26-35' },
    { value: '36-45', text: '36-45' },
    { value: '46-55', text: '46-55' },
    { value: '56-65', text: '56-65' },
    { value: 'Over 65', text: 'Über 65' }
  ],

  GENDER_OPTIONS: [
    { text: '-- Bitte auswählen --', value: null },
    { value: 'männlich', text: 'Männlich' },
    { value: 'weiblich', text: 'Weiblich' },
    { value: 'divers', text: 'Divers' }
  ],

  ACCENT_OPTIONS: [
    { text: '-- Bitte auswählen --', value: null },
    { value: 'schwäbischAlemannisch', text: 'Schwäbisch-Alemannisch' },
    { value: 'bairisch', text: 'Bairisch' },
    {
      value: 'ostfränkisch',
      text: 'Ostfränkisch (Main- oder Oberfränkisch)'
    },
    { value: 'südfränkisch', text: 'Südfränkisch (Südrheinfränkisch)' },
    { value: 'hessisch', text: 'Ehemaliges Rheinfränkisch (Hessisch)' },
    { value: 'mittelfränkisch', text: 'Mittelfränkisch' },
    { value: 'thüringisch', text: 'Thüringisch' },
    { value: 'obersächsisch', text: 'Obersächsisch (Meißnisch)' },
    { value: 'lausitzisch', text: 'Lausitzisch und ehemaliges Schlesisch' },
    { value: 'niederfränkisch', text: 'Niederfränkisch' },
    { value: 'niedersächsisch', text: 'Niedersächsisch' },
    { value: 'märkisch', text: 'Märkisch-Brandenburgisch' },
    { value: 'mecklenburgisch', text: 'Mecklenburgisch-Vorpommersch' },
    { value: 'friesisch', text: 'Friesisch' },
    { value: 'nordfriesisch', text: 'Nordfriesisch' },
    { value: 'helgoländer', text: 'Helgoländer Friesisch' },
    {
      value: 'DdutscheSprachinseln',
      text: 'Deutsche Sprachinseln und untergegangene Dialekte'
    },
    { value: 'schweizerDialekte', text: 'Schweizer Dialekte' },
    { value: 'osterreichischeDialekte', text: 'Österreichische Dialekte' },
    {
      value: 'sonstigeDialekteAkzente',
      text: 'Sonstige Dialekte / Akzente'
    },
    { value: 'keinDialekt', text: 'Kein Dialekt' },
    { value: 'other', text: 'Andere' }
  ],

  REGION_OPTIONS: [
    { text: '-- Bitte auswählen --', value: null },
    { value: 'badenWürttemberg', text: 'Baden-Württemberg' },
    { value: 'bayern', text: 'Bayern' },
    { value: 'berlin', text: 'Berlin' },
    { value: 'brandenburg', text: 'Brandenburg' },
    { value: 'bremen', text: 'Bremen' },
    { value: 'hamburg', text: 'Hamburg' },
    { value: 'hessen', text: 'Hessen' },
    { value: 'mecklenburgVorpommern', text: 'Mecklenburg-Vorpommern' },
    { value: 'niedersachsen', text: 'Niedersachsen' },
    { value: 'nordrheinWestfalen', text: 'Nordrhein-Westfalen' },
    { value: 'rheinlandPfalz', text: 'Rheinland-Pfalz' },
    { value: 'saarland', text: 'Saarland' },
    { value: 'sachsen', text: 'Sachsen' },
    { value: 'sachsenAnhalt', text: 'Sachsen-Anhalt' },
    { value: 'schleswigHolstein', text: 'Schleswig-Holstein' },
    { value: 'thüringen', text: 'Thüringen' },
    { value: 'schweiz', text: 'Schweiz' },
    { value: 'osterreich', text: 'Österreich' },
    { value: 'andere', text: 'Andere' }
  ],

  LANGUAGE_OPTIONS: [
    { text: '-- Bitte auswählen --', value: null },
    { value: 'deutsch', text: 'Deutsch' },
    { value: 'englisch', text: 'Englisch' },
    { value: 'albanisch', text: 'Albanisch (Toskisch)' },
    { value: 'arabisch', text: 'Arabisch' },
    { value: 'armenisch', text: 'Armenisch' },
    { value: 'aseri', text: 'Aseri (Aserbaidschanisch)' },
    { value: 'bosnisch', text: 'Bosnisch' },
    { value: 'bulgarisch', text: 'Bulgarisch' },
    { value: 'chinesisch', text: 'Chinesisch' },
    { value: 'dänisch', text: 'Dänisch' },
    { value: 'dari', text: 'Dari (Neupersisch)' },
    { value: 'estnisch', text: 'Estnisch' },
    { value: 'finnisch', text: 'Finnisch' },
    { value: 'flämisch', text: 'Flämisch' },
    { value: 'französisch', text: 'Französisch' },
    { value: 'georgisch', text: 'Georgisch' },
    { value: 'gilbertesisch', text: 'Gilbertesisch' },
    { value: 'griechisch', text: 'Griechisch' },
    { value: 'hebräisch', text: 'Hebräisch (Iwrith)' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'irisch', text: 'Irisch' },
    { value: 'isländisch', text: 'Isländisch' },
    { value: 'italienisch', text: 'Italienisch' },
    { value: 'japanisch', text: 'Japanisch' },
    { value: 'kasachisch', text: 'Kasachisch' },
    { value: 'katalanisch', text: 'Katalanisch' },
    { value: 'khmer', text: 'Khmer' },
    { value: 'kirgiesisch', text: 'Kirgiesisch' },
    { value: 'koreanisch', text: 'Koreanisch' },
    { value: 'kroatisch', text: 'Kroatisch' },
    { value: 'kurdisch', text: 'Kurdisch' },
    { value: 'laotisch', text: 'Laotisch' },
    { value: 'latain', text: 'Latain' },
    { value: 'lettisch', text: 'Lettisch' },
    { value: 'litauisch', text: 'Litauisch' },
    { value: 'makedonisch', text: 'Makedonisch' },
    { value: 'maltesisch', text: 'Maltesisch' },
    { value: 'moldauisch', text: 'Moldauisch' },
    { value: 'mongolisch', text: 'Mongolisch' },
    { value: 'neugriechisch', text: 'Neugriechisch' },
    { value: 'niederländisch', text: 'Niederländisch' },
    { value: 'norwegisch', text: 'Norwegisch' },
    { value: 'persisch', text: 'Persisch' },
    { value: 'polnisch', text: 'Polnisch' },
    { value: 'portugiesisch', text: 'Portugiesisch' },
    { value: 'quechua', text: 'Quechua (Indianersprache)' },
    { value: 'rumänisch', text: 'Rumänisch' },
    { value: 'russisch', text: 'Russisch' },
    { value: 'schwedisch', text: 'Schwedisch' },
    { value: 'serbisch', text: 'Serbisch' },
    { value: 'singhalesisch', text: 'Singhalesisch' },
    { value: 'slowakisch', text: 'Slowakisch' },
    { value: 'slowenisch', text: 'Slowenisch' },
    { value: 'spanisch', text: 'Spanisch' },
    { value: 'tadschiekisch', text: 'Tadschiekisch' },
    { value: 'thailändisch', text: 'Thailändisch' },
    { value: 'tschechisch', text: 'Tschechisch' },
    { value: 'türkisch', text: 'Türkisch' },
    { value: 'turkmenisch', text: 'Turkmenisch' },
    { value: 'ukrainisch', text: 'Ukrainisch' },
    { value: 'ungarisch', text: 'Ungarisch' },
    { value: 'urdu', text: 'Urdu' },
    { value: 'usbekisch', text: 'Usbekisch' },
    { value: 'vietnamesisch', text: 'Vietnamesisch' },
    { value: 'andere', text: 'Andere' }
  ],

  SECOND_LANGUAGE_OPTIONS: [
    { text: 'None', value: null },
    { value: 'deutsch', text: 'Deutsch' },
    { value: 'englisch', text: 'Englisch' },
    { value: 'albanisch', text: 'Albanisch (Toskisch)' },
    { value: 'arabisch', text: 'Arabisch' },
    { value: 'armenisch', text: 'Armenisch' },
    { value: 'aseri', text: 'Aseri (Aserbaidschanisch)' },
    { value: 'bosnisch', text: 'Bosnisch' },
    { value: 'bulgarisch', text: 'Bulgarisch' },
    { value: 'chinesisch', text: 'Chinesisch' },
    { value: 'dänisch', text: 'Dänisch' },
    { value: 'dari', text: 'Dari (Neupersisch)' },
    { value: 'estnisch', text: 'Estnisch' },
    { value: 'finnisch', text: 'Finnisch' },
    { value: 'flämisch', text: 'Flämisch' },
    { value: 'französisch', text: 'Französisch' },
    { value: 'georgisch', text: 'Georgisch' },
    { value: 'gilbertesisch', text: 'Gilbertesisch' },
    { value: 'griechisch', text: 'Griechisch' },
    { value: 'hebräisch', text: 'Hebräisch (Iwrith)' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'irisch', text: 'Irisch' },
    { value: 'isländisch', text: 'Isländisch' },
    { value: 'italienisch', text: 'Italienisch' },
    { value: 'japanisch', text: 'Japanisch' },
    { value: 'kasachisch', text: 'Kasachisch' },
    { value: 'katalanisch', text: 'Katalanisch' },
    { value: 'khmer', text: 'Khmer' },
    { value: 'kirgiesisch', text: 'Kirgiesisch' },
    { value: 'koreanisch', text: 'Koreanisch' },
    { value: 'kroatisch', text: 'Kroatisch' },
    { value: 'kurdisch', text: 'Kurdisch' },
    { value: 'laotisch', text: 'Laotisch' },
    { value: 'latain', text: 'Latain' },
    { value: 'lettisch', text: 'Lettisch' },
    { value: 'litauisch', text: 'Litauisch' },
    { value: 'makedonisch', text: 'Makedonisch' },
    { value: 'maltesisch', text: 'Maltesisch' },
    { value: 'moldauisch', text: 'Moldauisch' },
    { value: 'mongolisch', text: 'Mongolisch' },
    { value: 'neugriechisch', text: 'Neugriechisch' },
    { value: 'niederländisch', text: 'Niederländisch' },
    { value: 'norwegisch', text: 'Norwegisch' },
    { value: 'persisch', text: 'Persisch' },
    { value: 'polnisch', text: 'Polnisch' },
    { value: 'portugiesisch', text: 'Portugiesisch' },
    { value: 'quechua', text: 'Quechua (Indianersprache)' },
    { value: 'rumänisch', text: 'Rumänisch' },
    { value: 'russisch', text: 'Russisch' },
    { value: 'schwedisch', text: 'Schwedisch' },
    { value: 'serbisch', text: 'Serbisch' },
    { value: 'singhalesisch', text: 'Singhalesisch' },
    { value: 'slowakisch', text: 'Slowakisch' },
    { value: 'slowenisch', text: 'Slowenisch' },
    { value: 'spanisch', text: 'Spanisch' },
    { value: 'tadschiekisch', text: 'Tadschiekisch' },
    { value: 'thailändisch', text: 'Thailändisch' },
    { value: 'tschechisch', text: 'Tschechisch' },
    { value: 'türkisch', text: 'Türkisch' },
    { value: 'turkmenisch', text: 'Turkmenisch' },
    { value: 'ukrainisch', text: 'Ukrainisch' },
    { value: 'ungarisch', text: 'Ungarisch' },
    { value: 'urdu', text: 'Urdu' },
    { value: 'usbekisch', text: 'Usbekisch' },
    { value: 'vietnamesisch', text: 'Vietnamesisch' },
    { value: 'andere', text: 'Andere' }
  ]
}
