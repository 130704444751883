export const TRANSLATIONS: TranslationsConfigProps = {
  headerWebsiteTitle: 'Voice Collection Service',
  progressListWelcome: 'Willkommen',
  progressListYourDetails: 'Nutzerdaten',
  progressListBackgroundAudioCheck: 'Background Audio Check',
  progressListRecordings: 'Aufnahmen',
  progressListDone: 'Fertig',
  homeHelloText: 'Hallo',
  homeNeedHelpText: 'Wir brauchen deine Hilfe',
  homeThankYouText: 'Wir bei Sky möchten unsere Sprachsteuerung noch besser machen. Wir sammeln daher Sprachbeispiele aus ganz Deutschland, damit euch unsere Sprachsteuerung zukünftig noch besser versteht.',
  homeSamplesText: 'We need even more samples - especially from kids under 10. So if you fancy getting involved, it\'s not too late and there are 10 Sky Store Movie Vouchers up for grabs. The deadline for entry is the 18th December.',
  homeSpareTimeText: 'Hast Du 5-10 Minuten für uns? Lies Dir bitte die rechtlichen Informationen in dem Link unten durch und klicke auf \'Weiter\', wenn Du damit einverstanden bist.',
  homeNextText: 'Weiter',
  homeCloseText: 'Schließen',
  userDetailsTitleText: 'Deine Daten',
  userDetailsSessionText: [
    'Diese Session ist nur für',
    'eine',
    'Person. Du kannst nach deiner Session weitere Personen aufnehmen.'
  ],
  userDetailsFormLabelsText: [
    'Altersgruppe *',
    'Geschlecht *',
    'Dein Dialekt (bestmögliche Näherung) *',
    'Deine Region (bestmögliche Näherung) *',
    'Muttersprache *',
    'Zweite Muttersprache'
  ],
  userDetailsNextText: 'Weiter',
  backgroundAudioCheckTitleText: 'Background Audio Check',
  backgroundAudioCheckPermissionForMicText: 'Wir brauchen dein OK um über dein Mikrofon die Hintergrundgeräusche zu überprüfen.',
  backgroundAudioCheckFindQuietSpaceText: 'Suche möglichst einen ruhigen Ort für deine Aufnahmen.',
  backgroundAudioCheckHeadsetText: 'Wenn du ein Headset bzw Kopfhörer hast, benutze es bitte und stelle sicher, dass es als Mikrofon für deinen Browser eingestellt ist.',
  backgroundAudioCheckCheckText: 'Hintergrundgeräusche prüfen',
  backgroundAudioCheckAudioGoodText: [
    'Deine Hintergrundgeräusche sind niedrig genug!',
    'Du bist bereit für die Aufnahmen.'
  ],
  backgroundAudioCheckBeginRecordingText: 'Aufnahme beginnen',
  backgroundAudioCheckErrorText: 'Die Geräusche im Hintergrund sind ein bisschen zu laut. Bitte suche dir einen ruhigeren Ort.',
  recordingsTitleText: 'Aufnahme',
  recordingsGetReadyText: 'Mach Dich bereit…',
  recordingsSayWakewordText: 'Sag: ',
  recordingsBeginText: 'Starten',
  recordingsHaveAListenText: 'Höre dir hier deine Aufnahmen nochmal an',
  recordingsRedoRecordingText: 'Nochmal neu aufnehmen',
  recordingsNextRecordingText: 'Nächste Aufnahme',
  recordingsSpeechInstructionsText: 'Sag den Text genau ein Mal. Dann warte, bis der Timer abläuft.',
  recordingsStayQuietText: 'Bitte bleibe so leise wie möglich während des countdown!',
  recordingsAreYouStillThereText: 'Hallo...bist du noch da?',
  recordingsGoneQuietText: 'Es ist so still geworden...',
  recordingsRefreshPromptText: 'Klicke den Button unten um die Seite zu aktualisieren',
  recordingsRefreshText: 'Seite neu laden',
  recordingsReturnHelloText: 'Hallo',
  recordingsReturnText: 'Du hast deine letzte Session nicht beendet. Möchtest du hier weiter machen?',
  recordingsReturnCarryOnText: 'Ja, Session fortsetzen.',
  recordingsReturnRestartText: 'Nein, von vorne beginnen.',
  errorBrowserUnsupportedTitle: 'Browser wird nichts Unterstützt ',
  errorBrowserUnsupportedText: 'Nur Chrome, Firefox, Safari.. und Edge  werden im moment Unterstützt',
  doneThankYouText: 'Danke',
  doneAllDoneText: 'Fertig!',
  doneContactText: 'Um weitere Familienmitglieder hinzuzufügen, klicke auf \'Weitere Personen hinzufügen\'',
  doneAddMoreText: 'Weitere Personen hinzufügen',
  toastErrorErrorTitle: 'Fehler',
  toastErrorRecordingsTitle: 'Aufnahmen',
  toastErrorUserCreate: 'Nutzer konnte nicht registriert werden',
  toastErrorBackgroundNoise: 'Hintergrundgeräusche konnten nicht überprüft werden',
  toastErrorMicrophoneAccess: 'Kein Zugang zu Mikrofon. Bitte Berechtigung aktualisieren und erneut versuchen.',
  toastErrorRecordPartCreate: 'Deine Aufnahme konnte nicht gesendet werden.',
  toastErrorTokenUnauthorised: 'Der Zugang zu diesem Feature konnte nicht gewährt werden. Bitte versuche es noch einmal, aktualisiere die Seite oder melde dich nochmal an.',
  homeContinueText: 'Weiter',
  homeTermsAndConditions: 'Bedingungen und Konditionen',
  homeRequiredCheckboxes: 'Die mit einem Sternchen gekennzeichneten Kästchen müssen ausgefüllt werden!',
  recordingsContinueSessionText: 'Sitzung fortsetzen',
  recordingsSatisfiedText: 'Sind Sie mit dem Ergebnis zufrieden?',
  recordingsFinishText: 'Sitzung beenden',
  recordingsProgressText: 'Ihr Fortschritt:',
  contactEmail: 'email@addr.de'
}
